@import '../../scss/index.scss';

.menu_superior{
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo{
        margin-left: 10%;
        height: 90%;
        width: 30%;
        img{
            height: 100%;
        }
    }

    .datos_menu{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title_text{
            margin-top: -10px;
            font-family: 'Roboto';
            font-size: 32px;
            font-weight: 700;
            color: $text-texto;
        }
        .activity{
            margin-top: -40px;
            font-size: 18px;
            font-family: 'Roboto';
            p{
                color: white;
                font-weight: 500;
            }
            strong{
                color: $text-actividad;
            }
        }
    }

}