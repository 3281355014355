.video_imagen{
    margin-top: 50px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .imagen{
        width: 40%;
        height: 500px;
        img{
            height: 100%;
        }
    }

    .video {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .botones{
            margin-top: 50px;
            width: 60%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            img{
                width: 150px;
                padding-left: 50px;
                padding-right: 50px;
                &:hover{
                    cursor: pointer;
                    opacity: 0.5;
                }
            }
        }
    }
}